<template>
    <DxList
        :data-source="DanhSachTrangThaiHang"
        height="174px"
        item-template="list-item"
    >
        <template #list-item="{ data, index }">
            <div class="row align-center" style="padding: 8px 16px">
                <div class="shrink">
                    <DxButton
                        :active-state-enabled="false"
                        :focus-state-enabled="false"
                        :hover-state-enabled="false"
                        :text="data.soLuong + '' || '0'"
                        type="normal"
                        styling-mode="text"
                        width="36px"
                        height="36px"
                        class="
                            nut-chuthich-style
                            clear-rounder
                            nut-trangthai
                            text-xs-center
                        "
                        :class="data.idTrangThai == 1 ? 'chuthich-border' : ''"
                        :style="`background-color:${data.maMau};border-radius:4px;`"
                    />
                </div>
                <div class="grow pl-2" style="white-space: nowrap">
                    {{ `${data.tenTrangThai}: ${data.soLuong}` }}
                </div>
            </div>
        </template>
    </DxList>
</template>
<script>
import { DxButton, DxList } from "devextreme-vue";
export default {
    components: {
        DxButton,
        DxList,
    },
    props: {
        DanhSachTrangThaiHang: { type: Array, default: () => [] },
    },
    methods: {},
};
</script>
<style scoped>
>>> .nut-chuthich-style.dx-button-has-text {
    min-width: unset !important;
}
>>> .nut-chuthich-style .dx-button-content {
    padding: 0 !important;
}
>>> .nut-chuthich-style.nut-trangthai span {
    color: white;
}
>>> .chuthich-border {
    border: 1px solid #dadce0;
}
</style>
